import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faHandPointRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import { BaseURL } from "../Components/Api";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
function UserDetail() {
  const baseurl = BaseURL();
  const [teamDetail, setTeamDetail] = useState([]);
  const [skils, setSkils] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const options = {
    loop: true,
    margin: 10,
    smartSpeed: 450, // Speed of the transition
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    autoplay: true, // Enable autoplay
    dots: true, // Show dots navigation
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  useEffect(() => {
    getTeamDetail();
  }, []);

  const getTeamDetail = async () => {
    let url =window.location.href;
    let parts = url.split('/');
    let lastPart = parts[parts.length - 1];
    try {
      const response = await Axios.get(baseurl + `/api/ourteam/detail/${lastPart}`);
      console.log(response)
      if (response.data.status === true) {
        setisLoading(false)
        setTeamDetail(response.data.data);
        setSkils(response.data.data.skills);
      }

    } catch (error) {
      console.error(error);
      console.log(error);
    }
  };
  return (
    <>
    <Helmet>
        <title>DETAIL</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 about-top-img"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay1 overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start  pb-5">
            <p class="breadcrumbs mb-2"><span class="mr-2"><NavLink to="/">Home <i class="ion-ios-arrow-forward"></i></NavLink></span> <span class="mr-2"><NavLink to="/our_team">Our Team <i class="ion-ios-arrow-forward"></i></NavLink></span> <span>Staff Detail<i class="ion-ios-arrow-forward"></i></span></p>
              <h1 class="mb-0 bread">{!isLoading && (teamDetail.name)}</h1>
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <a href="index.html">
                  {!isLoading && (teamDetail.role.title==='ceo'?'Founder & CEO':teamDetail.role.title)} <i class="ion-ios-arrow-forward"></i>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <>
         <section class="ftco-section ftco-no-pt bg-light ">
        <div class="container">
        <div class="row d-flex no-gutters text-start">
              <div class="col-md-6 pl-md-5 py-md-5">
                <div class="heading-section pe-md-4 pt-md-5">
                  <h2 class="placeholder-glow">  <span className="placeholder col-12"></span></h2>
                  <span class="placeholder-glow subheading mb-4"> <span className="placeholder col-6"></span></span>
                  <p class="placeholder-glow">
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-6"></span>
                  </p>
                </div>
              </div>
              <div class="col-md-6 d-flex">
                {/* ourteam-ceo-image */}
                <div style={{ background: '#808080a1' }} class="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                </div>
              </div>
            </div>
            <p class="placeholder-glow text-start">
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-6"></span>
                  </p>
        </div>
      </section>
        </>

):(
  <>
  <section class="ftco-section ftco-no-pt bg-light ">
        <div class="container">
          <div class="row d-flex no-gutters text-start">
            <div class="col-md-6 pl-md-5 py-md-5">
              <div class="heading-section pe-md-4 pt-md-5">
               
                <p>
                {teamDetail?.bio}
                </p>
                <div class="wrapper">
							<div class="row no-gutters">
								<div class="col-lg-12 col-md-12 d-flex text-start align-items-stretch">
									<div class="info-wrap bg-primary w-100 rounded p-4">
					        
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-phone"><FontAwesomeIcon icon={faPhone}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Phone:</span> <a href={`tel://${teamDetail.phone}`}>{teamDetail.phone}</a></p>
						          </div>
					          </div>
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-paper-plane"><FontAwesomeIcon icon={faPaperPlane}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Email:</span> <a href={`mailto:${teamDetail.email}`}>{teamDetail.email}</a></p>
						          </div>
					          </div>
                 
				          </div>
								</div>
							</div>
						</div>
              </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                <img className="team-img" src={teamDetail?.media[0]?.original_url} />
              </div>
            </div>
          </div>
          <section className="mt-5">
        <div className="overlay"></div>
        <div className="container">
              <div className="blockquote text-start">
                <h5>Achievements :</h5>
                <h6>{teamDetail.achievements}</h6>
              </div>
        </div>
      </section>
      
        </div>
      </section>
      <section className="ftco-section testimony-section bg-light">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-center pb-2 mb-3">
            <div className="col-md-7 heading-section heading-section-white text-center ">
              {/* <span className="subheading">Testimonies</span> */}
              <h2>Areas of Expertise</h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <OwlCarousel className="owl-theme " {...options}>
                {skils.map((item,index)=>(
                  <div className="item" key={index}>
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"><FontAwesomeIcon icon={faHandPointRight}/></span>
                    </div>
                    <div className="text">
                      <h5 className="fw-bold">{item.name}</h5>
                    </div>
                  </div>
                </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
  </>
)}
      
    </>
  );
}
export default UserDetail;
