import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';
import './Styles/style.css'
import './Styles/animate.css'
import './Styles/flaticon.css'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import 'popper.js/dist/umd/popper.min.js';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Pages/Home'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import About from './Pages/About';
import Services from './Pages/Services';
import Blogs from './Pages/Blogs';
import ContactUs from './Pages/ContactUs';
import BlogDetail from './Pages/BlogDetail';
import OurTeam from './Pages/OurTeam';
import UserDetail from './Pages/UserDetail';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './Images/icon.png'
import Review from './Pages/Review';
import AllReviews from './Pages/AllReview';
import OurApproach from './Pages/OurApproach';
import TermsConditions from './Pages/TermsConditions';
function App() {
  return (
    <div className="App">
      <FloatingWhatsApp
      phoneNumber="+447723348140"
      accountName="Taxvise"
      statusMessage="Online"
      chatMessage="Hello! How can we help you today?"
      avatar={logo}
      style={{ left: '15px', right: 'auto' }}
      notification
    />
      <Router>
        <Navbar/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/blogs" element={<Blogs/>}/>
            <Route path="/contact" element={<ContactUs/>}/>
            <Route path="/blog_detail/:id" element={<BlogDetail/>}/>
            <Route path="/our_team" element={<OurTeam/>}/>
            <Route path="/staff_detail/:id" element={<UserDetail/>}/>
            <Route path="/review" element={<Review/>}/>
            <Route path="/cutomer_reviews" element={<AllReviews/>}/>
            <Route path="/our_approach" element={<OurApproach/>}/>
            <Route path="/terms_and_conditions" element={<TermsConditions/>}/>
          </Routes>
          <Footer/>
      </Router>
    </div>
  );
}

export default App;
