import React from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
function TermsConditions(){

    return(
        <>
          <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 our_approach"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                Terms and Conditions <i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Terms and Conditions</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-4 mb-4">
        <h6 className="fw-bold">Welcome to TaxVise. By using our services, you agree to the following terms and conditions. Please read them carefully before engaging our services.</h6>
      <div className="text-start">
        <h3>Introduction</h3>
        <p>TaxVise provides professional tax and accounting services in the UK. Our services include but are not limited to tax returns, VAT returns, confirmation statements, tax planning, HMRC investigation advice, payroll registration, consultancy, workplace pension services, bookkeeping, statutory accounts, online accounting, and company management accounts.</p>
      </div>
      <div className="text-start">
        <h3>Eligibility</h3>
        <p>Our services are available to businesses and individuals who comply with UK regulations. By engaging with TaxVise, you confirm that all information provided is accurate and up to date.</p>
      </div>
      <div className="text-start">
        <h3>Client Responsibilities</h3>
        <p>Clients are responsible for providing accurate and timely information and documents needed for us to deliver our services. TaxVise is not liable for any errors resulting from inaccurate or incomplete data provided by the client.</p>
      </div>
      <div className="text-start">
        <h3>Confidentiality</h3>
        <p>We treat all client information with the highest level of confidentiality and adhere to data protection laws applicable in the UK. Your information will only be shared with authorized personnel and in accordance with legal requirements.</p>
      </div>
      <div className="text-start">
        <h3>Fees and Payment</h3>
        <p>Service fees are agreed upon in advance and are outlined in the engagement letter or service agreement. Payments must be made according to the specified payment terms. Late payments may incur additional charges.</p>
      </div>
      <div className="text-start">
        <h3>Governing Law</h3>
        <p>These terms and conditions are governed by the laws of England and Wales. Any disputes will be subject to the exclusive jurisdiction of the courts in the UK.</p>
      </div>
      <div className="text-start">
        <h3>Amendments</h3>
        <p>TaxVise reserves the right to update these terms and conditions at any time. Clients will be notified of any significant changes.</p>
      </div>
      
      </div>
        </>
    )
}

export default TermsConditions;