import React,{useState} from "react";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faBars, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink,useLocation } from "react-router-dom";
import logo from '../Images/logo.png'
function Navbar(){
	const [activeLink, setActiveLink] = useState('/');
	const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the navbar visibility
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
	React.useEffect(() => {
	  setActiveLink(location.pathname);
	}, [location.pathname]);
  
	const handleLinkClick = (path) => {
	  setActiveLink(path);
	};
    return(
        <>
        <div className="wrap">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="bg-wrap">
							<div className="row">
								<div className="col-md-6 d-flex align-items-center">
									<p className="mb-0 phone pl-md-2">
										<a href="tel:01484 655560" className="mr-2"><span className="fa fa-phone mr-1"><FontAwesomeIcon icon={faPhone}/></span>01484 655560</a> 
										<a href="mailto:info@taxvise.co.uk"><span className="fa fa-paper-plane mr-1"> <FontAwesomeIcon icon={faPaperPlane}/></span>info@taxvise.co.uk</a>
									</p>
								</div>
								<div className="col-md-6 d-flex justify-content-md-end">
									<div className="social-media">
						    		<p className="mb-0 d-flex">
						    			<a  target="blank" href="https://www.facebook.com/TaxVise" className="d-flex align-items-center justify-content-center"><span className="fa fa-facebook"><FontAwesomeIcon icon={faFacebook}><i className="sr-only">Facebook</i></FontAwesomeIcon></span></a>
						    			<a  target="blank" href="https://www.twitter.com/TaxVise" className="d-flex align-items-center justify-content-center"><span className="fa fa-twitter"><FontAwesomeIcon icon={faTwitter}><i className="sr-only">Twitter</i></FontAwesomeIcon></span></a>
						    			<a  target="blank" href="https://www.instagram.com/TaxVise" className="d-flex align-items-center justify-content-center"><span className="fa fa-instagram"><FontAwesomeIcon icon={faInstagram}><i className="sr-only">Instagram</i></FontAwesomeIcon></span></a>
						    		</p>
					        </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
  <div className="container">
    <NavLink className="navbar-brand" to="/">
      <img className="navbar-logo" style={{ height: 'auto', width: '10em' }} src={logo} />
    </NavLink>
    <button className="navbar-toggler" onClick={toggleNavbar} type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded={isOpen} aria-label="Toggle navigation">
      <span className="fa fa-bars"><FontAwesomeIcon icon={faBars}/></span> Menu
    </button>
    
    <div className={`collapse navbar-collapse text-start ${isOpen ? 'show' : ''}`} id="ftco-nav">
      <ul className="navbar-nav m-auto">
        <li onClick={toggleNavbar} className={`nav-item ${activeLink === '/' ? 'active' : ''}`}>
          <NavLink to="/" className="nav-link" onClick={() => handleLinkClick('/')}>Home</NavLink>
        </li>
        <li onClick={toggleNavbar} className={`nav-item ${activeLink === '/about' ? 'active' : ''}`}>
          <NavLink to="/about" className="nav-link" onClick={() => handleLinkClick('/about')}>About</NavLink>
        </li>
        <li onClick={toggleNavbar} className="nav-item dropdown">
          <NavLink
            to="/services"
            className="nav-link dropdown-toggle"
            id="servicesDropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(e) => e.preventDefault()} // Prevents the default link behavior
          >
            Services
          </NavLink>
          <div className="dropdown-menu" aria-labelledby="servicesDropdown">
            <NavLink to="/services" className="dropdown-item">Service</NavLink>
            <NavLink to="/our_approach" className="dropdown-item">Our Approach</NavLink>
          </div>
        </li>
        <li onClick={toggleNavbar} className={`nav-item ${activeLink === '/our_team' ? 'active' : ''}`}>
          <NavLink to="/our_team" className="nav-link" onClick={() => handleLinkClick('/our_team')}>Our Team</NavLink>
        </li>
        <li onClick={toggleNavbar} className={`nav-item ${activeLink === '/blogs' ? 'active' : ''}`}>
          <NavLink to="/blogs" className="nav-link" onClick={() => handleLinkClick('/blogs')}>Blog</NavLink>
        </li>
        <li className={`nav-item ${activeLink === '/contact' ? 'active' : ''}`}>
          <NavLink to="/contact" className="nav-link" onClick={() => handleLinkClick('/contact')}>Contact Us</NavLink>
        </li>
        <li onClick={toggleNavbar} className={`nav-item ${activeLink === '/review' ? 'active' : ''}`}>
          <NavLink to="/review" className="nav-link" onClick={() => handleLinkClick('/review')}>Review</NavLink>
        </li>
      </ul>
    </div>
  </div>
</nav>

        </>
    )
}

export default Navbar;