import React, { useState,useEffect } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import Axios from "axios";
import { BaseURL } from "./Api";
function Counter() {
  const baseurl=BaseURL();
  const [counterData,setCounterData]=useState([]);
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  if (inView && !hasStarted) {
    setHasStarted(true);
    setCount(2000); // or any target number
  }

  useEffect(() => {
    GetCounterDetail();
}, []);

const GetCounterDetail = async () => {
    try {
        const response=await Axios.get(baseurl+`/api/counter-list`);
        if(response.data.status===true)
          {
            setCounterData(response.data.data)
          }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
  return (
    <>
      <section className="ftco-counter bg-light ftco-no-pt" id="section-counter" >
        <div className="container pt-5">
          <div className="row" ref={ref}>
            <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ">
              <div className="block-18 text-center">
                <div className="text">
                  <strong className="number" data-number="50">
                    <CountUp className="number" end={inView ? Number(counterData.year_of_experience) : 0} />
                  </strong>
                </div>
                <div className="text">
                  <span>Years of Experienced</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ">
              <div className="block-18 text-center">
                <div className="text">
                  <strong className="number" data-number="8500">
                    <CountUp className="number" end={inView ? Number(counterData.case_completed) : 0} />
                  </strong>
                </div>
                <div className="text">
                  <span>Cases Completed</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ">
              <div className="block-18 text-center">
                <div className="text">
                  <strong className="number" data-number="20">
                    <CountUp className="number" end={inView ? Number(counterData.awards_won) : 0} />
                  </strong>
                </div>
                <div className="text">
                  <span>Awards Won</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ">
              <div className="block-18 text-center">
                <div className="text">
                  <strong className="number" data-number="50">
                    <CountUp className="number" end={inView ? Number(counterData.expert_consultant) : 0} />
                  </strong>
                </div>
                <div className="text">
                  <span>Expert Consultant</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Counter;
