import React from "react";

function Packages(){
    return(
        <>
        <section class="ftco-section bg-light">
    	<div class="container">
    		<div class="row justify-content-center pb-5 mb-3">
          <div class="col-md-7 heading-section text-center ">
          	<span class="subheading">Price &amp; Plans</span>
            <h2>Affordable Packages</h2>
          </div>
        </div>
    		<div class="row">
    			<div class="col-md-6 col-lg-3 ">
	          <div class="block-7">
	            <div class="text-center">
	            	<span class="excerpt d-block">Personal</span>
	            <span class="price"><sup>$</sup> <span class="number">49</span> <sub>/mos</sub></span>
	            
	            <ul class="pricing-text mb-5">
	              <li><span class="fa fa-check mr-2"></span>Brand Strategy</li>
	              <li><span class="fa fa-check mr-2"></span>Online Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Branding Services</li>
	              <li><span class="fa fa-check mr-2"></span>Creative Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Sales Management</li>
	            </ul>

	            <a href="#" class="btn btn-primary d-block px-2 py-3">Get Started</a>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-6 col-lg-3 ">
	          <div class="block-7">
	            <div class="text-center">
            	<span class="excerpt d-block">Business</span>
	            <span class="price"><sup>$</sup> <span class="number">79</span> <sub>/mos</sub></span>
	            
	            <ul class="pricing-text mb-5">
	              <li><span class="fa fa-check mr-2"></span>Brand Strategy</li>
	              <li><span class="fa fa-check mr-2"></span>Online Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Branding Services</li>
	              <li><span class="fa fa-check mr-2"></span>Creative Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Sales Management</li>
	            </ul>

	            <a href="#" class="btn btn-primary d-block px-2 py-3">Get Started</a>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-6 col-lg-3 ">
	          <div class="block-7">
	            <div class="text-center">
	            	<span class="excerpt d-block">Ultimate</span>
	            <span class="price"><sup>$</sup> <span class="number">109</span> <sub>/mos</sub></span>
	            
	            <ul class="pricing-text mb-5">
	              <li><span class="fa fa-check mr-2"></span>Brand Strategy</li>
	              <li><span class="fa fa-check mr-2"></span>Online Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Branding Services</li>
	              <li><span class="fa fa-check mr-2"></span>Creative Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Sales Management</li>
	            </ul>

	            <a href="#" class="btn btn-primary d-block px-2 py-3">Get Started</a>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-6 col-lg-3 ">
	          <div class="block-7">
	            <div class="text-center">
	            	<span class="excerpt d-block">Premium</span>
	            <span class="price"><sup>$</sup> <span class="number">149</span> <sub>/mos</sub></span>
	            
	            <ul class="pricing-text mb-5">
	              <li><span class="fa fa-check mr-2"></span>Brand Strategy</li>
	              <li><span class="fa fa-check mr-2"></span>Online Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Branding Services</li>
	              <li><span class="fa fa-check mr-2"></span>Creative Marketing</li>
	              <li><span class="fa fa-check mr-2"></span>Sales Management</li>
	            </ul>

	            <a href="#" class="btn btn-primary d-block px-2 py-3">Get Started</a>
	            </div>
	          </div>
	        </div>
	      </div>
    	</div>
    </section>
        </>
    )
}
export default Packages;