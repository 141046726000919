import { faClock, faGlobe, faMapMarked, faMapMarker, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import { ToastContainer,toast } from "react-toastify";
import Helmet from "react-helmet";
function ContactUs(){
	const baseurl=BaseURL();
  const [formData, setFormData] = useState({
    title: "Mr",
    interest: "Accouting",
    name: "",
    email: "",
    company: "",
    subject: "",
    reason: "Need Physical Appointment",
    message: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const handleSubmit =async (e) => {
    e.preventDefault();
    setisLoading(true)
    let isValid = true;
    let newErrors = {};

    // Validate form fields
    for (let key in formData) {
      if (!formData[key]) {
        isValid = false;
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    }

    if (!isValid) {
		setisLoading(false)
      setErrors(newErrors);
    } else {
      setErrors({});
      // Process form data here (e.g., send it to the server)

	  var data={
		 'title':formData.title,
            'full_name' : formData.name,
            'email' :formData.email,
            'company_position' : formData.company, // Adjust as needed
            'subject' : formData.subject,
            'reason_for_contact' : formData.reason,
            'area_of_interest' : formData.interest,
            'description' : formData.message
	  }
	  try{
			const response =await Axios.post(baseurl+'/api/contactus',data);
			setisLoading(false)
			if(response.data.status===true){
				toast.success(response.data.message);
				setFormData({
					title: "Mr",
					interest: "Accouting",
					name: "",
					email: "",
					company: "",
					subject: "",
					reason: "Need Physical Appointment",
					message: "",
				  })
			}else{
				toast.error(response.data.message);
			}

	  }catch(error){
		setisLoading(false)
		console.log(error);
    // Check if the error response contains the expected data structure.
    if (error.response && error.response.data && error.response.data.errors) {
      // Loop through the errors and dynamically access each key.
      error.response.data.errors.forEach((item) => {
        Object.keys(item).forEach((key) => {
          // Assuming the value is an array of error messages.
          if (Array.isArray(item[key]) && item[key].length > 0) {
            toast.error(item[key][0]); // Display the first error message for each key.
          }
        });
      });
    } else if (error.response && error.response.data && error.response.data.message) {
      // Optionally handle a single error message.
      toast.error(error.response.data.message);
    } else {
      // Handle other unexpected errors.
      toast.error('An unexpected error occurred. Please try again later.');
    }
	  }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
    return(
        <>
        <Helmet>
        <title>CONTACT US</title>
      </Helmet>
		<ToastContainer/>
           <section class="hero-wrap hero-wrap-2 about-top-img"  data-stellar-background-ratio="0.5">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-end">
          <div class="col-md-9 text-start pb-5">
          	<p class="breadcrumbs mb-2"><span class="mr-2"><NavLink to="/">Home <i class="ion-ios-arrow-forward"></i></NavLink></span> <span>Contact Us <i class="ion-ios-arrow-forward"></i></span></p>
            <h1 class="mb-0 bread">Contact Us</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section bg-light">
    	<div class="container">
    		<div class="row justify-content-center">
					<div class="col-md-12">
						<div class="wrapper">
							<div class="row no-gutters">
								<div class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
									<div class="contact-wrap w-100 p-md-5 p-4">
										<h3 class="mb-4">Get in touch</h3>
										<div id="form-message-warning" class="mb-4"></div> 
										    <form
      method="POST"
      id="contactForm"
      name="contactForm"
      onSubmit={handleSubmit}
      className="contactForm text-start"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="title">Title</label>
            <select
              name="title"
              className="form-control"
              aria-label="Default select example"
              value={formData.title}
              onChange={handleChange}
            >
              <option value="Mr">Mr.</option>
              <option value="Mrs">Mrs.</option>
              <option value="Miss">Miss.</option>
            </select>
            {errors.title && <p className="text-danger">{errors.title}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="interest">Area of interest</label>
            <select
              name="interest"
              className="form-control"
              aria-label="Default select example"
              value={formData.interest}
              onChange={handleChange}
            >
              <option value="Accouting">Accouting</option>
              <option value="Income Tax">Income Tax</option>
              <option value="VAT">VAT</option>
            </select>
            {errors.interest && <p className="text-danger">{errors.interest}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="name">Full Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p className="text-danger">{errors.name}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="email">Email Address</label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="company">Company/Position</label>
            <input
              type="text"
              className="form-control"
              name="company"
              id="company"
              placeholder="Company/Position"
              value={formData.company}
              onChange={handleChange}
            />
            {errors.company && <p className="text-danger">{errors.company}</p>}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="label" htmlFor="subject">Subject</label>
            <input
              type="text"
              className="form-control"
              name="subject"
              id="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && <p className="text-danger">{errors.subject}</p>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="label" htmlFor="reason">Reason for Contact *</label>
            <select
              className="form-control"
              name="reason"
              aria-label="Default select example"
              value={formData.reason}
              onChange={handleChange}
            >
              <option value="Need Physical Appointment">Need Physical Appointment</option>
              <option value="Need Telephone Appointment">Need Telephone Appointment</option>
              <option value="Something Else">Something Else</option>
            </select>
            {errors.reason && <p className="text-danger">{errors.reason}</p>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="label" htmlFor="message">Message</label>
            <textarea
              name="message"
              className="form-control"
              id="message"
              cols="30"
              rows="4"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <p className="text-danger">{errors.message}</p>}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
			{isLoading?(
				<div className="submitting"><div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
				</div> </div>
			):(
				<input type="submit" value="Send Message" className="btn btn-primary" />
			)}
            
            
          </div>
        </div>
      </div>
    </form>
									</div>
								</div>
								<div class="col-lg-4 col-md-5 d-flex text-start align-items-stretch">
									<div class="info-wrap bg-primary w-100 p-md-5 p-4">
										<h3>Contact details</h3>
										<p class="mb-4">Please let us know if you have a question, want to leave a comment, or would like further information about Our services at TaxVise.</p>
					        	<div class="dbox w-100 d-flex align-items-start">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-map-marker"><FontAwesomeIcon icon={faMapMarked}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Address:</span>169 Blackmoorfoot Road, Crosland Moor, Huddersfield HD4 5AP.</p>
						          </div>
					          </div>
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-phone"><FontAwesomeIcon icon={faPhone}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Phone:</span> <a href="tel://01484 655560">01484 655560</a></p>
						          </div>
					          </div>
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-paper-plane"><FontAwesomeIcon icon={faPaperPlane}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Email:</span> <a href="mailto:info@taxvise.co.uk">info@taxvise.co.uk</a></p>
						          </div>
					          </div>
                              <div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-globe"><FontAwesomeIcon icon={faClock}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><a >Mon-Fri 9:00 - 18:00 <br/> Sat-Sun CLOSED</a></p>
						          </div>
					          </div>
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-globe"><FontAwesomeIcon icon={faGlobe}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Website:</span> <a target="blank" href="https://www.taxvise.co.uk/">www.taxvise.co.uk</a></p>
						          </div>
					          </div>
				          </div>
								</div>
							</div>
						</div>
					</div>
				</div>
    	</div>
    </section>
    <div style={{width: '100%'}}><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=169%20Blackmoorfoot%20Rd,%20Crosland%20Moor,%20Huddersfield%20HD4%205AP,%20UK+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>
        </>
    )
}
export default ContactUs;