import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BaseURL } from "../Components/Api";
import Axios from "axios";
import {useNavigate} from 'react-router'
import Helmet from "react-helmet";
function OurTeam() {
  const navigation=useNavigate();
  const baseurl = BaseURL();
  const [ceoDetail, setCeoDetail] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    try {
      const response = await Axios.get(baseurl + '/api/ourteam');
      console.log(response)
      if (response.data.status === true) {
        var ceo = response.data.data.filter(item => item.role.title === 'CEO');
        var otherteam = response.data.data.filter(item => item.role.title !== 'CEO');
        setCeoDetail(ceo);
        setIsLoading(false);
        setTeamList(otherteam);
      }

    } catch (error) {
      console.error(error);
      console.log(error);
    }
  };

  const ViewDetail=(id)=>{
    navigation(`/staff_detail/${id}`);
  }
  return (
    <>
    <Helmet>
        <title>OUR TEAM</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 team-top-img"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start  pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                  Our Team <i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread"> Our Team</h1>
            </div>
          </div>
        </div>
      </section>

      <section class="ftco-section ftco-no-pt bg-light ">
        <div class="container">
          {isLoading ? (
            <div class="row d-flex no-gutters text-start">
              <div class="col-md-6 pl-md-5 py-md-5">
                <div class="heading-section pe-md-4 pt-md-5">
                  <h2 class="placeholder-glow">  <span className="placeholder col-12"></span></h2>
                  <span class="placeholder-glow subheading mb-4"> <span className="placeholder col-6"></span></span>
                  <p class="placeholder-glow">
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-6"></span>
                  </p>
                </div>
              </div>
              <div class="col-md-6 d-flex">
                {/* ourteam-ceo-image */}
                <div style={{ background: '#808080a1' }} class="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                </div>
              </div>
            </div>
          ) : (
            <>
            {ceoDetail.map((item,index)=>(
               <div class="row d-flex no-gutters text-start" key={index}>
               <div class="col-md-6 pl-md-5 py-md-5">
                 <div class="heading-section pe-md-4 pt-md-5">
                   <h2 class="">{item.name}</h2>
                   <span class="subheading mb-4">Founder & CEO</span>
                   <p>
                   {item.bio.slice(0,500)}
                   </p>
                   <p onClick={()=>ViewDetail(item.id)} className="pb-0 read_more_link mb-0 mt-2">read more</p>
                 </div>
               </div>
               <div class="col-md-6 d-flex">
                 <div class="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                   <img style={{width:'100%'}} src={item?.media[0]?.original_url} />
                 </div>
               </div>
             </div>
            ))}
            </>
          )}

        </div>
      </section>
      <section class="ftco-section ftco-no-pt bg-light ">
        <div class="container">
          <div class="row d-flex no-gutters text-start">
            {isLoading ? (
              <>
              <div class="col-md-4  pl-md-3 py-md-2">
                <div class="card">
                  <div style={{ height: '22em', background: '#808080a1' }} className='card-image'>
                  </div>            <div class="card-body">
                    <div class="heading-section ">
                      <h4 class="mb-0 placeholder-glow"> <span className="placeholder col-12"></span></h4>
                      <span class="subheading fw-bold placeholder-glow"><span className="placeholder col-9"></span></span>
                    </div>
                    <p className="pb-0 mb-0 mt-2 placeholder-glow"><span className="placeholder col-6"></span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-4  pl-md-3 py-md-2">
                <div class="card">
                  <div style={{ height: '22em', background: '#808080a1' }} className='card-image'>
                  </div>            <div class="card-body">
                    <div class="heading-section ">
                      <h4 class="mb-0 placeholder-glow"> <span className="placeholder col-12"></span></h4>
                      <span class="subheading fw-bold placeholder-glow"><span className="placeholder col-9"></span></span>
                    </div>
                    <p className="pb-0 mb-0 mt-2 placeholder-glow"><span className="placeholder col-6"></span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-4  pl-md-3 py-md-2">
                <div class="card">
                  <div style={{ height: '22em', background: '#808080a1' }} className='card-image'>
                  </div>            <div class="card-body">
                    <div class="heading-section ">
                      <h4 class="mb-0 placeholder-glow"> <span className="placeholder col-12"></span></h4>
                      <span class="subheading fw-bold placeholder-glow"><span className="placeholder col-9"></span></span>
                    </div>
                    <p className="pb-0 mb-0 mt-2 placeholder-glow"><span className="placeholder col-6"></span></p>
                  </div>
                </div>
              </div>
              </>
            ) : (
              <>
              {teamList.map((item,index)=>(
                  <div class="col-md-4  pl-md-3 py-md-2" key={index}>
                  <div class="card">
                    <img  src={item?.media[0]?.original_url} class="card-img-top team-img" alt="..." />
                    <div class="card-body">
                      <div class="heading-section ">
                        <h4 class="mb-0">{item.name}</h4>
                        <span class="subheading fw-bold">{item.role.title}</span>
                      </div>
                      <p onClick={()=>ViewDetail(item.id)} className="pb-0 read_more_link mb-0 mt-2">read more</p>
                    </div>
                  </div>
                </div>
              ))}
              </>
            )}
          </div>
        </div>
      </section>

    </>
  )
}

export default OurTeam;