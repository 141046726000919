import React,{useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
function Blogs() {
  const baseurl=BaseURL();
  const navigation=useNavigate();
  const [blogList,setBlogList]=useState([]);
  const [isLoading,setIsLoading]=useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  useEffect(()=>{
    getblogs();
  },[currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getblogs=async()=>{
    try{
      const response=await Axios.get(baseurl+`/api/blog?page=${currentPage}`);
      if(response.data.status===true)
      {
        setIsLoading(false);
        setBlogList(response.data.data);
        setTotalPages(response.data.data.last_page);
      }
  
    }catch(error){
      console.error(error);
        console.log(error);
    }
  };

  const  BlogDetail=(id)=>{
    navigation(`/blog_detail/${id}`);
  } 
  return (
    <>
    <Helmet>
        <title>BLOGS</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 about-top-img"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                  Blog <i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Blog</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="ftco-section">
        <div class="container">
          {isLoading ? (
            <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
                     <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
                     <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
             </div>
          ):(
            <>
            {blogList?.data && blogList.data.length !==0 ?(
              <div class="row d-flex">
              {blogList.data.map((item,index)=>(
                <div key={index} class="col-md-4 d-flex ">
                <div style={{cursor:'pointer'}} onClick={() => BlogDetail(item.id)} class="blog-entry align-self-stretch">
                  <div class=" rounded">
                    <img
                      style={{
                        height: "18em",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={item.media[0]?.original_url}
                    />
                  </div>
                  <div class="text p-4">
                    <div class="meta mb-2">
                      <div>
                        <a >{moment(item.created_at).format('LL')}</a>
                      </div>
                      {/* <div>
                        <a>Admin</a>
                      </div> */}
                      {/* <div>
                        <a  class="meta-chat">
                          <span class="fa fa-comment"></span> 3
                        </a>
                      </div> */}
                    </div>
                    <h3 class="heading text-start mb-0 pb-0">
                        {item.title}
                    </h3>
                    <p class="text-start">{item.content.slice(0,100)+'...'}</p>
                  </div>
                </div>
              </div>
              ))}
          </div>
            ):(
              <div className="text-center">
              <h5>Sorry! No Blogs Found.</h5>
            </div>
            )}
            </>
            
          )}
          {blogList?.data && blogList?.data.length !==0 &&(
          <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li style={{cursor:'pointer'}} className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link"  onClick={() => handlePageChange(currentPage - 1)} tabIndex="-1">Previous</a>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                      <a className="page-link"  onClick={() => handlePageChange(page)}>{page}</a>
                    </li>
                  ))}
                  <li style={{cursor:'pointer'}} className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link"  onClick={() => handlePageChange(currentPage + 1)}>Next</a>
                  </li>
                </ul>
              </nav>
              )}
        </div>
      </section>
    </>
  );
}
export default Blogs;
