import React, { useState, useEffect } from "react";
import person1 from '../Images/person_1.jpg'
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import moment from "moment/moment";
import Helmet from "react-helmet";
function BlogDetail(){
  const baseurl=BaseURL();
  const [blogDetail,setBlogDetail]=useState([]);
  const [recentBlog,setRecentBlog]=useState([]);
  const [isLoading,setIsLoading]=useState(true);
  const [isLoadingLates,setIsLoadingLates]=useState(true);
  useEffect(() => {
    GetBlogDetail();
    GetRecentBlog();
}, []);

const GetBlogDetail = async () => {
    const path = window.location.pathname;
    const parts = path.split('/');
    const lastPart = parts[parts.length - 1];
    try {
        const response=await Axios.get(baseurl+`/api/blog/detail/${lastPart}`);
        if(response.data.status===true)
          {
            setIsLoading(false);
            setBlogDetail(response.data.data);
           
          }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const GetRecentBlog = async () => {
 
  try {
      const response=await Axios.get(baseurl+`/api/blog/recent`);
      console.log(response)
      if(response.data.status===true)
        {
          setIsLoadingLates(false);
          setRecentBlog(response.data.data);
         
        }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
    return(
        <>
        <Helmet>
        <title>BLOG DETAIL</title>
      </Helmet>
         <section class="hero-wrap hero-wrap-2 " style={{backgroundImage:`url(${blogDetail?.media ? blogDetail?.media[0]?.original_url:''})`}} data-stellar-background-ratio="0.5">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-end">
          <div class="col-md-9 text-start pb-5">
          	<p class="breadcrumbs mb-2"><span class="mr-2"><a >Home <i class="ion-ios-arrow-forward"></i></a></span> <span class="mr-2"><a >Blog <i class="ion-ios-arrow-forward"></i></a></span> <span>Detail<i class="ion-ios-arrow-forward"></i></span></p>
            <h1 class="mb-0 bread">{blogDetail?.title}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-degree-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 text-start">
          <div dangerouslySetInnerHTML={{ __html: blogDetail?.longcontent }} />
          </div> 

          <div class="col-lg-4 sidebar pl-lg-5 text-start">
            {/* <div class="sidebar-box">
              <form action="#" class="search-form">
                <div class="form-group">
                  <span class="fa fa-search"></span>
                  <input type="text" class="form-control" placeholder="Type a keyword and hit enter"/>
                </div>
              </form>
            </div> */}
            {/* <div class="sidebar-box ">
              <div class="categories">
                <h3>Services</h3>
                <li><a href="#">Market Analysis <span class="fa fa-chevron-right"></span></a></li>
                <li><a href="#">Accounting Advisor <span class="fa fa-chevron-right"></span></a></li>
                <li><a href="#">General Consultancy <span class="fa fa-chevron-right"></span></a></li>
                <li><a href="#">Structured Assesment <span class="fa fa-chevron-right"></span></a></li>
              </div>
            </div> */}

            <div class="sidebar-box ">
              <h3>Recent Blog</h3>
              {isLoadingLates ? (
                <>
                 <div class="block-21 mb-2 d-flex">
                 <p class="blog-img mr-4" >
                 <img  style={{height: "100%",width: "100%",objectFit: "cover", }} src={person1} alt="Image placeholder"/>
                 </p>
                 <div class="text">
                   <h3 class="heading pb-0 mb-0 placeholder-glow" >
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-3"></span>
                    </h3>
                  
                 </div>
               </div>
               <div class="block-21 mb-2 d-flex">
                 <p class="blog-img mr-4" >
                 <img  style={{height: "100%",width: "100%",objectFit: "cover", }} src={person1} alt="Image placeholder"/>
                 </p>
                 <div class="text">
                   <h3 class="heading pb-0 mb-0 placeholder-glow" >
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-3"></span>
                    </h3>
                  
                 </div>
               </div>
               <div class="block-21 mb-2 d-flex">
                 <p class="blog-img mr-4" >
                 <img  style={{height: "100%",width: "100%",objectFit: "cover", }} src={person1} alt="Image placeholder"/>
                 </p>
                 <div class="text">
                   <h3 class="heading pb-0 mb-0 placeholder-glow" >
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-3"></span>
                    </h3>
                  
                 </div>
               </div>
               </>
              ):(
                <>
                {recentBlog.map((item,index)=>(
                  <div class="block-21 mb-2 d-flex">
                          <p class="blog-img mr-4" >
                          <img  style={{height: "100%",width: "100%",objectFit: "cover", }} src={item.media[0]?.original_url} alt="Image placeholder"/>
                          </p>
                          <div class="text">
                            <h3 class="heading pb-0 mb-0" ><a>{item.title.slice(0,30)+'...'}</a></h3>
                            <div class="meta">
                              <div><a ><span class="icon-calendar"></span>{moment(item.created_at).format('LL')} </a></div>
                              {/* <div><a ><span class="icon-chat"></span> 19</a></div> */}
                            </div>
                          </div>
                        </div>
                  ))}
                </>
              )}
              
            </div>

            <div class="sidebar-box ">
              <h3>Tag Cloud</h3>
              {blogDetail?.tags && (
                  <div class="tagcloud">
                  {blogDetail.tags.length >=1 && blogDetail.tags.map((item,index)=>(
                    <a key={index} class="tag-cloud-link">{item.title}</a>
                  )) }
                </div>
              )}
            </div>
            <div class="sidebar-box ">
              <h3>Short Description</h3>
              <p>{blogDetail?.content}</p>
            </div>
          </div>

        </div>
      </div>
    </section>
        </>
    )
}
export default BlogDetail;