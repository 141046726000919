import { faBank, faBarChart, faDollarSign, faLightbulb, faParagraph, faPlaneDeparture, faVanShuttle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
function OurApproach(){

    return(
        <>
         <Helmet>
        <title>our approach</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 our_approach"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                  Our Approach <i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Our Approach</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container text-start mt-4">
        <p className="text-dark">Welcome to TaxVise: Your Trusted Tax Consultants in the UK Navigating the complex terrain of taxes can be a daunting task for individuals and businesses alike. With ever-changing regulations and intricate financial landscapes, having a trusted advisor by your side is essential. At TaxVise, we pride ourselves on being your reliable partner in all tax matters. Led by a team of highly skilled professionals, TaxVise is a leading firm of Chartered Certified Accountants and Chartered Tax Advisers based in the UK. Our commitment to excellence and client satisfaction sets us apart in the industry. Why Choose TaxVise? Expertise: Our team comprises Chartered Certified Accountants and Chartered Tax Advisers who possess extensive knowledge and experience in tax laws and regulations. Whether you’re an individual taxpayer or a corporate entity, we have the expertise to provide tailored solutions to meet your specific needs.</p>
        <div class="row mt-4 mb-4">
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day plane-hover"><FontAwesomeIcon icon={faPlaneDeparture}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">Personal Taxation</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>Self-assessment tax returns</span></li>
                        <li class="widget-49-meeting-item"><span>Income tax planning</span></li>
                        <li class="widget-49-meeting-item"><span>Inheritance tax planning</span></li>
                        <li class="widget-49-meeting-item"><span>Capital gains tax advice</span></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day chart-hover"><FontAwesomeIcon icon={faBarChart}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">Corporate Taxation</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>Tax-efficient structuring</span></li>
                        <li class="widget-49-meeting-item"><span>R&D tax credits</span></li>
                        <li class="widget-49-meeting-item"><span>International tax planning</span></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day dollar-hover"><FontAwesomeIcon icon={faDollarSign}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">VAT Services</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>VAT registration and compliance</span></li>
                        <li class="widget-49-meeting-item"><span>VAT advice and planning</span></li>
                        <li class="widget-49-meeting-item"><span>VAT inspections and disputes</span></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day bank-hover"><FontAwesomeIcon icon={faBank}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">Tax Planning and Advisory</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>Bespoke tax planning strategies</span></li>
                        <li class="widget-49-meeting-item"><span>Tax-efficient investments</span></li>
                        <li class="widget-49-meeting-item"><span>Retirement planning</span></li>
                        <li class="widget-49-meeting-item"><span>Succession planning</span></li>

                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day buld-hover"><FontAwesomeIcon icon={faLightbulb}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">HMRC Representation</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>Dealing with HMRC enquiries</span></li>
                        <li class="widget-49-meeting-item"><span>Dispute resolution and negotiation</span></li>
                        <li class="widget-49-meeting-item"><span>Appeals and tribunal representation</span></li>

                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class=" card-margin">
            <div class="card-body pt-0 pb-0">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper d-flex mt-2 align-items-center">
                        <div class="widget-49-date-primary ">
                            <span class="widget-49-date-day van-hover"><FontAwesomeIcon icon={faVanShuttle}/></span>
                        </div>
                        <div class="widget-49-meeting-info">
                            <h6 class="text-primary fw-bold">Payroll Services</h6>
                        </div>
                    </div>
                    <ol class="widget-49-meeting-points">
                        <li class="widget-49-meeting-item"><span>Payroll processing</span></li>
                        <li class="widget-49-meeting-item"><span>PAYE compliance</span></li>
                        <li class="widget-49-meeting-item"><span>Auto-enrolment for pensions</span></li>
                        <li class="widget-49-meeting-item"><span>CIS compliance for contractors</span></li>

                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
      </div>
        </>
    )
}
export default OurApproach;