import React, { useState } from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import { ToastContainer,toast } from "react-toastify";
function Review(){
  const baseurl=BaseURL();
  const questions = [
    'Quality',
    'Customer service',
    'Experience',
    'Price',
    'Attitude of the staff',
    'Professionalism of the staff'
  
];
const [formData, setFormData] = useState({
  firstName: '',
  lastName: '',
  email: '',
  dateOfService: '',
  serviceName: '',
  review: '',
  suggestions: '',
});

const [errors, setErrors] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [isValid, setIsValid] = useState(true);
const ratings = ['Very Satisfied', 'Satisfied', 'Somewhat Satisfied', 'Not Satisfied'];
const ratingsbackend = ['very_satisfied', 'satisfied', 'somewhat_satisfied', 'not_satisfied'];
// State to store the selected ratings for each question
const [selections, setSelections] = useState({});

// Handle change event when a radio button is selected
const handleSelectionChange = (questionIndex, ratingIndex) => {
    setSelections({
        ...selections,
        [questionIndex]: ratingIndex
    });
};
const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
  
};

const validate = () => {
  let newErrors = {};
  if (!formData.firstName) newErrors.firstName = "First Name is required";
  if (!formData.lastName) newErrors.lastName = "Last Name is required";
  if (!formData.email) {
    newErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    newErrors.email = "Email format is invalid";
  }
  if (!formData.dateOfService) newErrors.dateOfService = "Date of Service is required";
  if (!formData.serviceName) newErrors.serviceName = "Service Name is required";
  if (!formData.review) newErrors.review = "Review is required";
  if (formData.review.length>250) newErrors.review = "Review cannot be more than 500 characters";
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
const validateForm = () => {
  // Check if every question has a selected rating
  const allQuestionsAnswered = questions.every((_, qIndex) => selections[qIndex] !== undefined);
  setIsValid(allQuestionsAnswered);
  return allQuestionsAnswered;
};
const handleSubmit =async (e) => {
  e.preventDefault();
  if (validate()) {
    if (validateForm()) {
      setIsLoading(true);
      var data = {
        'first_name': formData.firstName,
        'last_name': formData.lastName,
        'email': formData.email,
        'services_name': formData.serviceName,
        'services_date': formData.dateOfService,
        'quality': ratingsbackend[selections[0]], // Quality rating
        'customer_services': ratingsbackend[selections[1]], // Customer service rating
        'experience': ratingsbackend[selections[2]], // Experience rating
        'price': ratingsbackend[selections[3]], // Price rating
        'attitude_staff': ratingsbackend[selections[4]], // Attitude of the staff rating
        'professionalism_staff': ratingsbackend[selections[5]], // Professionalism of the staff rating
        'review': formData.review,
        'suggestions': formData.suggestions,
      };

      try {
        const response=await Axios.post(baseurl+`/api/customer-review/store`,data);
        setIsLoading(false);
        if(response.data.status===true)
          {
            setSelections({});
            setFormData({
              firstName: '',
              lastName: '',
              email: '',
              dateOfService: '',
              serviceName: '',
              review: '',
              suggestions: '',
            })
            toast.success(response.data.message);
           
          }else{
            toast.error(response.data.message);
          }
    } catch (error) {
      console.error('Error fetching data:', error); // Logs the error for debugging purposes.
      setIsLoading(false); // Stops the loading state.

      // Check if the error response contains the expected data structure.
      // Check if the error response contains the expected data structure.
  if (error.response && error.response.data && error.response.data.errors) {
    // Loop through the errors and dynamically access each key.
    error.response.data.errors.forEach((item) => {
      Object.keys(item).forEach((key) => {
        // Assuming the value is an array of error messages.
        if (Array.isArray(item[key]) && item[key].length > 0) {
          toast.error(item[key][0]); // Display the first error message for each key.
        }
      });
    });
  } else if (error.response && error.response.data && error.response.data.message) {
    // Optionally handle a single error message.
    toast.error(error.response.data.message);
  } else {
    // Handle other unexpected errors.
    toast.error('An unexpected error occurred. Please try again later.');
  }
    }
  }
    // You can now send formData to your backend or process it further
  }
};
    return(
        <>
        <Helmet>
        <title>Review</title>
      </Helmet>
      <ToastContainer/>
      <section
        class="hero-wrap hero-wrap-2 about-page-top-img"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start  pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                 Review<i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Customer Review</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="mt-3 mb-3">
            <h5>Thank you for choosing Taxvise. We'd love to hear feedback from our customers. Please fill out the form below:</h5>
          </div>
            <div className="col-md-6">
            <div class="form-group text-start">
              <label className="fw-bold">First Name</label>
              <input
          type="text"
          className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
        />
              {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}

            </div>
            </div>
            <div className="col-md-6">
            <div class="form-group text-start">
              <label className="fw-bold">Last Name</label>
              <input
          type="text"
          className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
        />
        {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </div>
            </div>
            <div className="col-md-6">
            <div class="form-group text-start">
              <label className="fw-bold">Email</label>
              <input
          type="email"
          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
          name="email"
          placeholder="example@gmail.com"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            </div>
            <div className="col-md-6">
            <div class="form-group text-start">
              <label className="fw-bold">Date of Service</label>
              <input
          type="date"
          className={`form-control ${errors.dateOfService ? 'is-invalid' : ''}`}
          name="dateOfService"
          value={formData.dateOfService}
          onChange={handleChange}
        />
            </div>
            </div>
            <div className="col-md-6">
            <div class="form-group text-start">
              <label className="fw-bold">Service Name</label>
              <input
          type="text"
          className={`form-control ${errors.serviceName ? 'is-invalid' : ''}`}
          name="serviceName"
          placeholder="Service Name"
          value={formData.serviceName}
          onChange={handleChange}
        />
        {errors.serviceName && <div className="invalid-feedback">{errors.serviceName}</div>}
            </div>
            </div>
          <div className="col-md-12">
          <div className="table-responsive">
    <table className="table table-bordered">
        <thead className="thead-light">
            <tr>
                <th scope="col">Criteria</th>
                {ratings.map((rating, index) => (
                    <th key={index} scope="col">{rating}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {questions.map((question, qIndex) => (
                <tr key={qIndex}>
                    <th className="text-start" scope="row">{question}</th>
                    {ratings.map((_, rIndex) => (
                        <td key={rIndex}>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={`question-${qIndex}`} 
                                    id={`question-${qIndex}-rating-${rIndex}`}
                                    checked={selections[qIndex] === rIndex}
                                    onChange={() => handleSelectionChange(qIndex, rIndex)}
                                />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={`question-${qIndex}-rating-${rIndex}`}>
                                </label>
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
</div>

            {!isValid && (
                    <div className="alert alert-danger" role="alert">
                        Please answer all questions.
                    </div>
                )}
          </div>
          <div className="col-md-12">
            <div class="form-group text-start">
              <label className="fw-bold">Review</label>
              <textarea
          className={`form-control ${errors.review ? 'is-invalid' : ''}`}
          name="review"
          rows="5"
          placeholder="Please write a review that will be shown to other users to share your experience with us."
          value={formData.review}
          onChange={handleChange}
        ></textarea>
        {errors.review && <div className="invalid-feedback">{errors.review}</div>}            </div>
            </div>
            <div className="col-md-12">
            <div class="form-group text-start">
              <label className="fw-bold">Suggestions</label>
              <textarea
          className="form-control"
          name="suggestions"
          rows="5"
          placeholder="Any Suggestions"
          value={formData.suggestions}
          onChange={handleChange}
        ></textarea>
            </div>
            </div>
            {isLoading?(
				<div style={{margin:'auto'}} className="submitting"><div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
				</div> </div>
			):(
        <button onClick={handleSubmit} type="submit" className="btn btn-primary">Submit</button>
			)}
        </div>
      </div>
        </>
    )
}
export default Review;