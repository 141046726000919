import React,{useEffect, useState} from "react";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
function NewsAndBlog() {
  const navigation=useNavigate();
  const baseurl=BaseURL();
  const [recentBlog,setRecentBlog]=useState([]);
  const [isLoading,setIsLoading]=useState(true);
  useEffect(() => {
    GetRecentBlog();
}, []);
const GetRecentBlog = async () => {
 
  try {
      const response=await Axios.get(baseurl+`/api/blog/recent`);
      if(response.data.status===true)
        {
          setIsLoading(false);
          setRecentBlog(response.data.data);
        }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};
const  BlogDetail=(id)=>{
  navigation(`/blog_detail/${id}`);
} 
  return (
    <>
    {recentBlog.length !==0 &&(
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center pb-5 mb-3">
            <div class="col-md-7 heading-section text-center ">
              <span class="subheading">News &amp; Blog</span>
              <h2>Latest news from our blog</h2>
            </div>
          </div>
          {isLoading ? (
            <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
                     <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
                     <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                         <div className='card-sl bootstrap-card-top '>
                             <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                             </div>
                             <div className="hr-devider"></div>
                             <div className='row p-2'>
                                 <h5 className="card-title placeholder-glow">
                                     <span className="placeholder col-12"></span>
                                     <span className="placeholder col-7"></span>
                                     <span className="placeholder col-6"></span>
                                 </h5>
                             </div>
                         </div>
                     </div>
             </div>
          ):(
            <div class="row d-flex">
              {recentBlog.map((item,index)=>(
                <div key={index} class="col-md-4 d-flex ">
                <div style={{cursor:'pointer'}} onClick={() => BlogDetail(item.id)} class="blog-entry align-self-stretch">
                  <div class=" rounded">
                    <img
                      style={{
                        height: "18em",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={item.media[0]?.original_url}
                    />
                  </div>
                  <div class="text p-4">
                    <div class="meta mb-2">
                      <div>
                        <a >{moment(item.created_at).format('LL')}</a>
                      </div>
                      {/* <div>
                        <a>Admin</a>
                      </div> */}
                      {/* <div>
                        <a  class="meta-chat">
                          <span class="fa fa-comment"></span> 3
                        </a>
                      </div> */}
                    </div>
                    <h3 class="heading text-start mb-0 pb-0">
                        {item.title}
                    </h3>
                    <p class="text-start">{item.content.slice(0,100)+'...'}</p>
                  </div>
                </div>
              </div>
              ))}
          </div>
          )}
        </div>
      </section>
      )}
    </>
  );
}
export default NewsAndBlog;
