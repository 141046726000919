import React,{useState,useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { BaseURL } from "./Api";
import Axios from "axios";
import { NavLink } from "react-router-dom";
function Feedback(){
  const baseurl=BaseURL();
  const [feedbacks, setFeedbacks] = useState([]);
    const options = {
        loop: true,
        margin: 10,
        smartSpeed: 450, // Speed of the transition
        autoplayTimeout: 3000, // Autoplay interval in milliseconds
        autoplay: true, // Enable autoplay
        dots: true, // Show dots navigation
        nav: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      };
      useEffect(()=>{
        GetFeedbacks();
      },[]);

      const GetFeedbacks=async()=>{
        try{
          const response =await Axios.get(baseurl+'/api/customer-review');
          console.log(response);
          if(response.data.status===true){
            setFeedbacks(response.data.data)
          }
      }catch(error){
        console.log(error);
        }
    }
    return(
        <>
        {feedbacks.length!==0 &&(
        <section className="ftco-section testimony-section bg-light">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section heading-section-white text-center ">
              <span className="subheading">Testimonies</span>
              <h2>Happy Clients &amp; Feedbacks</h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <OwlCarousel className="owl-theme " {...options}>
                {feedbacks.map((item,index)=>(
                <div key={index} className="item">
                  <div style={{height:'20em'}} className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"><FontAwesomeIcon icon={faQuoteLeft}/></span>
                    </div>
                    <div className="text">
                      <p className="mb-4 text-start">
                        {item.review.slice(0,250)}
                      </p>
                      <div className="d-flex align-items-center">
                        {/* <div className="user-img home-person-1"></div> */}
                        <div className="pl-3">
                          <p className="name"> {item.first_name} {item.last_name}</p>
                          {/* <span className="position">Marketing Manager</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
          <NavLink class="nav-item" to='/cutomer_reviews'><button className="btn btn-primary">All Reviews</button></NavLink>
        </div>
      </section>
      )}
        </>
    )
}
export default Feedback;