import React from "react";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faClock, faGlobe, faMapMarked, faMapMarker, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer class="footer">
        <div class="container-fluid px-lg-5">
          <div class="row">
            <div class="col-md-12 col-lg-9 py-5">
              <div class="row text-start">
                <div class="col-md-4  mb-md-0 mb-4">
                  <h2 class="footer-heading">About us</h2>
                  <p>
                  Welcome to TaxVise, your premier destination for expert tax guidance in the UK. As accredited professionals, we specialize in navigating the complexities of tax laws, providing tailored solutions to individuals and businesses. Trust us to optimize your tax position and ensure compliance with confidence.
                  </p>
                  <ul class="ftco-footer-social p-0">
                    <li class="">
                      <a
                       target="blank"
                        href="https://www.twitter.com/TaxVise"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Twitter"
                      >
                        <span class="fa fa-twitter"><FontAwesomeIcon icon={faTwitter}/></span>
                      </a>
                    </li>
                    <li class="">
                      <a
                       target="blank"
                        href="https://www.facebook.com/TaxVise"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Facebook"
                      >
                        <span class="fa fa-facebook"><FontAwesomeIcon icon={faFacebook}/></span>
                      </a>
                    </li>
                    <li class="">
                      <a
                      target="blank"
                        href="https://www.instagram.com/TaxVise"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Instagram"
                      >
                        <span class="fa fa-instagram"><FontAwesomeIcon icon={faInstagram}/></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-8">
                  <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">
                      <div class="row">
                      <div class="col-md-4 mb-md-0 mb-4">
                          <h2 class="footer-heading">Taxation</h2>
                          <ul class="list-unstyled">
                            <li>
                              <a  class="py-1 d-block">
                              Tax Returns
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              VAT Returns
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Confirmation Statements
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Tax Planning
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              HMRC Investigation Advice
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Payroll Registration
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Consultancy
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Workplace Pension
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-4 mb-md-0 mb-4">
                          <h2 class="footer-heading">Services</h2>
                          <ul class="list-unstyled">
                            <li>
                              <a  class="py-1 d-block">
                              Bookkeeping
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Statutory Accounts
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Online Accounting
                              </a>
                            </li>
                            <li>
                              <a  class="py-1 d-block">
                              Company Management Accounts
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-4 mb-md-0 mb-4">
                          <h2 class="footer-heading">Discover</h2>
                          <ul class="list-unstyled">
                            <li>
                              <NavLink target="blank" to="/about" class="py-1 d-block">
                                About us
                              </NavLink>
                            </li>
                            <li>
                              <NavLink target="blank" to="/contact" class="py-1 d-block">
                                Contract us
                              </NavLink>
                            </li>
                            <li>
                              <NavLink target="blank"  to="/terms_and_conditions" class="py-1 d-block">
                                Terms &amp; Conditions
                              </NavLink>
                            </li>
                            {/* <li>
                              <a href="#" class="py-1 d-block">
                                Policies
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-md-5">
                <div class="col-md-12">
                  <p class="copyright">
                  © {moment().format('YYYY')} Copyright Taxvise Limited. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-3 py-md-5 py-4 aside-stretch-right pl-lg-5">
            <div class="info-wrap  w-100 text-start">
										<h3>Contact details</h3>
					        	<div class="dbox w-100 d-flex align-items-center mt-2">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-phone"><FontAwesomeIcon icon={faPhone}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Phone:</span> <a href="tel://01484 655560">01484 655560</a></p>
						          </div>
					          </div>
                    <div class="dbox w-100 d-flex align-items-start">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-map-marker"><FontAwesomeIcon icon={faMapMarked}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Address:</span>169 Blackmoorfoot Road, Crosland Moor, Huddersfield HD4 5AP.</p>
						          </div>
					          </div>
					        	<div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-paper-plane"><FontAwesomeIcon icon={faPaperPlane}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><span>Email:</span> <a href="mailto:info@taxvise.co.uk">info@taxvise.co.uk</a></p>
						          </div>
					          </div>
                              <div class="dbox w-100 d-flex align-items-center">
					        		<div class="icon d-flex align-items-center justify-content-center">
					        			<span class="fa fa-globe"><FontAwesomeIcon icon={faClock}/></span>
					        		</div>
					        		<div class="text pl-3">
						            <p><a >Mon-Fri 9:00 - 18:00 <br/> Sat-Sun CLOSED</a></p>
						          </div>
					          </div>
				          </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
