import React,{useState,useEffect} from "react";
import Helmet from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleDot, faHandPointRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { BaseURL } from "../Components/Api";
import Axios from "axios";
import moment from "moment";
function AllReviews(){
    const baseurl=BaseURL();
    const [feedbacks, setFeedbacks] = useState([]);
 
        useEffect(()=>{
          GetFeedbacks();
        },[]);
  
        const GetFeedbacks=async()=>{
          try{
            const response =await Axios.get(baseurl+'/api/customer-review');
            console.log(response);
            if(response.data.status===true){
              setFeedbacks(response.data.data)
            }
        }catch(error){
          console.log(error);
          }
      }
    return(
        <>
        <Helmet>
        <title>Customer Reviews</title>
      </Helmet>
         <section class="hero-wrap hero-wrap-2 about-top-img "  data-stellar-background-ratio="0.5">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-end">
          <div class="col-md-9 text-start pb-5">
          	<p class="breadcrumbs mb-2"><span class="mr-2"><a >Home <i class="ion-ios-arrow-forward"></i></a></span> <span class="mr-2"><a >Customer Reviews <i class="ion-ios-arrow-forward"></i></a></span> </p>
            <h1 class="mb-0 bread">Customer Reviews</h1>
          </div>
        </div>
      </div>
    </section>
        <div className="all-review-bg">
            <div className="container pt-3 pb-3">
                {feedbacks.map((item,index)=>(
            <div key={index} style={{backgroundColor:'transparent'}} class="card text-start mt-3" >
  <div class="card-body">
    <h5 class="card-title">{item.first_name} {item.last_name} </h5>
    {/* <span > ( {moment(item.created_at).startOf('day').fromNow()} )</span> */}
    <p class="card-text">{item.review}</p>
    <div className="row">
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Quality: {item.quality}
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Attitude of the staff: {item.attitude_staff}
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Experience: {item.experience}
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Price: {item.price}
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Customer service: {item.customer_services}
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon className="text-primary" icon={faHandPointRight}/> Staff Professionalism: {item.professionalism_staff}
                    </div>
         
    </div>
  </div>
            </div> 
))}
            </div>
       
        </div>
        </>
    )
}
export default AllReviews;