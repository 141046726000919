import React,{useState,useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import NewsAndBlog from "../Components/NewsAndBlog";
import Counter from "../Components/Counter";
import Feedback from "../Components/Feedback";
import Faqs from "../Components/Faqs";
import Packages from "../Components/Packages";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import gif from '../Images/Grey Isometric Tax And Consulting Services Instagram Post.gif'
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
function Home() {
  const[sliderDetail,setSliderDetail]=useState([]);
  const baseurl=BaseURL();
  const optionstop = {
    items: 1, // Number of items to display
    loop: true, // Loop through items
    margin: 10, // Margin between items
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    animateOut: 'fadeOut', // Fade out animation
    animateIn: 'fadeIn', // Fade in animation
    smartSpeed: 450, // Speed of the transition
   
};

useEffect(()=>{
  getSliderDetail();
},[]);
const getSliderDetail=async()=>{
try{
  const response=await Axios.get(baseurl+'/api/slider');
  console.log(response);
  if(response.data.status===true){
    setSliderDetail(response.data.data);
  }
}catch(error){
  console.log(error)
}

}
  return (
    <>
     <Helmet>
        <title>HOME</title>
      </Helmet>
      <div className="hero-wrap top-home-slider">
	    <div className="home-slider owl-carousel">
        {sliderDetail.length !==0 ? (
           <OwlCarousel className="owl-theme" {...optionstop}>
          {sliderDetail.map((item,index)=>(
            <div key={index} className="item">
                <div className="slider-item  " style={{backgroundImage: `url(${item.media[0]?.original_url})`}}  >
                  <div className="overlay"></div>
                  <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                      <div className="col-md-8 ">
                        <div className="text w-100 text-center">
                          <h2>{item.short_title}</h2>
                          <h1 className="mb-4">{item.long_title}</h1>
                          <p><NavLink to="/contact" target="blank" className="btn btn-white">Connect with us</NavLink></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                 
          ))}
          </OwlCarousel>
        ):(
          <OwlCarousel className="owl-theme" {...optionstop}>
          <div className="item">
                <div className="slider-item slider-img-3" >
	      	<div className="overlay"></div>
	        <div className="container">
	          <div className="row no-gutters slider-text align-items-center justify-content-center">
		          <div className="col-md-8 ">
		          	<div className="text w-100 text-center">
		          		<h2>We Give Advice</h2>
			            <h1 className="mb-4">Expert Financial Advice</h1>
			            <p><NavLink to="/contact" target="blank" className="btn btn-white">Connect with us</NavLink></p>
		            </div>
		          </div>
		        </div>
	        </div>
	      </div>
                </div>
                </OwlCarousel>
        )}
          
	    </div>
	  </div>

      <section className="ftco-section ftco-no-pt bg-light text-start">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex">
              <div className="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                <img style={{width:'100%'}} src={gif}></img>
              </div>
            </div>
            <div className="col-md-6 pl-md-5 py-md-5">
              <div className="heading-section pl-md-4 pt-md-5">
                <span className="subheading">Welcome to Accounting</span>
                <h2 className="mb-4">We Are the Best Accounting Agency</h2>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-wealth"></span>
                </div>
                <div className="text pl-4">
                  <h4>Market Analysis</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accountant"></span>
                </div>
                <div className="text pl-4">
                  <h4>Accounting Advisor</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-teamwork"></span>
                </div>
                <div className="text pl-4">
                  <h4>General Consultancy</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accounting"></span>
                </div>
                <div className="text pl-4">
                  <h4>Structured Assestment</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ftco-section bg-light ftco-no-pt"
        style={{ zIndex: "1" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-accounting-1"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Accounting</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-tax"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Tax, Compliance &amp; Payroll</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-loan"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Financial Services</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-budget"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Growth &amp; Funding Access</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Counter/>

      <Feedback/>

      <Faqs/>

      <NewsAndBlog/>
      {/* <section class="ftco-section ftco-no-pb ftco-no-pt bg-secondary">
      <div class="container py-5">
    		<div class="row">
          <div class="col-md-7 d-flex align-items-center">
            <h2 class="mb-3 mb-sm-0" style={{color:'black',fontSize: '22px'}}>Sign Up for Your Free 1st Accounting Consultation</h2>
          </div>
          <div class="col-md-5 d-flex align-items-center">
            <form action="#" class="subscribe-form">
              <div class="form-group d-flex">
                <input type="text" class="form-control" placeholder="Enter email address"/>
                <input type="submit" value="Subscribe" class="submit px-3"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> */}

    {/* <Packages/> */}
    </>
  );
}

export default Home;
